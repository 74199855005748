/* Slider Container */
.slider {
    direction: ltr;
    position: relative;
  }
  
  /* Large Image Container */
  .sgb-gallery-large {
    position: relative;
    width: 100%;
    height: 60vh;
    max-height: 500px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none; /* Prevent text selection during drag */
  }
  
  /* Slider Track for Smooth Carousel */
  .slider-track {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
  }
  
  /* Individual Image Containers */
  .slider-image-container {
    flex: 0 0 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  
  /* Navigation Buttons */
  .slider-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.5);
    color: black;
    border: none;
    width: 35px;
    /*height: 50px;*/
    font-size: 30px;
    cursor: pointer;
    z-index: 10;
    font-family: monospace;
  }
  
  .slider-btn-left {
    left: 10px;
  }
  
  .slider-btn-right {
    right: 10px;
  }
  
  /* Thumbnails */
.sgb-gallery-thumbnails {
    display: flex;
    margin-top: 5px;
    overflow-x: auto;
    gap: 2px;
    white-space: nowrap; /* Ensure thumbnails stay in a single line */
  }
  
  .thumbnail-track {
    display: flex;
    overflow-x: hidden;
    gap: 2px;
    cursor: grab; /* Indicate draggable behavior */
    user-select: none; /* Prevent text selection during drag */
  }
  
  .thumbnail-track:active {
    cursor: grabbing; /* Change cursor during drag */
  }
  
  .sgb-gallery-thumbnails img {
    width: 50px;
    height: 35px;
    cursor: pointer;
    opacity: 0.7;
    border: 2px solid transparent;
    transition: opacity 0.3s ease;
    flex: 0 0 auto; /* Prevent thumbnails from shrinking */
  }
  
  .sgb-gallery-thumbnails img.active {
    opacity: 1;
    border-color: #575757;
  }
  
  .sgb-gallery-thumbnails img:hover {
    opacity: 1;
  }

  /* Responsive behavior for smaller screens */
    @media (max-width: 768px) {
        .sgb-gallery-large {
            height: 35vh !important; /* Adjust height for smaller screens */
        }
    }


  /* modded */