.whatsapp_signup {
    display:none;
}

@media only screen and (max-width: 500px) {
    .whatsapp_signup {        
        min-width: 90%;
        background-color: #3abd57;
        color: #fff;
        padding: 1em;
        margin: 1rem .75rem;
        line-height: 1;
        display:flex;
        border-radius: 5px;
    }

    .whatsapp_signup > a {
        display:flex;
        color: #fff;
        text-decoration: none;
    }

    .whatsapp_signup .icon {
        width: 20%;
    }

    .whatsapp_signup .content {
        width: 75%;
        font-size: 1.2em !important;
        font-weight: 800;
    }

    .whatsapp_signup .icon svg {
        width: 100%;
        height: 100%;
        /*display:'flex',alignItem:'center',minWidth:'130px', alignItems:'center'}}*/
    }

    .whatsapp_signup button {
        border-radius: 50px;
        padding: .1em 1.5em;
        font-size: 1em;
        margin-top: 0.5em;
        background: #fff;
        font-weight: 800;
        border:none;
        color: #000;
    }


}