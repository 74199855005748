#ConfirmationModal .heading  {
    font-size: 1.2rem;
}

#ConfirmationModal .heading h1 {
    font-weight:bold;
}

#ConfirmationModal .heading h2 {
    font-size: 1.35rem;
    margin-top: 1rem;
    font-weight: bold;
}

#ConfirmationModal img {
    width: 90%;
}

#ConfirmationModal .heading  span.orderId {
    color: #d34d65;
    font-size:1.1em;
    margin-right: 0.1em;
    /*text-decoration: underline;*/
}

#ConfirmationModal .heading  .attention {
    color:#d34d65;
    font-weight: bold;
    margin-top: 0.5em;
}