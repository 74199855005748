body {
/*  font-size: 12px; */
  overflow-x: hidden;
}

.ugly {
  font-size: 12px;
}


.modalBody {
  font-size:1em;
}

.extrasDiv {
  font-size: 1rem;
}

.pinkText {
  color: #cf5770;
}

.greenText {
  color: #1e5881;
}

.container{
  /*max-width: 960px !important;*/
}

.ugly h1 {
  font-size: 28px; 
  line-height: 36px;
  padding:0 0.5em 0 0;
}

.ugly h2 {
  font-size: 18px;
  line-height: 18px;
}

.ugly h3 {
  font-size: 22px;
  padding: 1rem;
}

.ugly h4 {
  font-size: 15px;
}

.ugly h5 {
  font-size: 16px;
}

.ugly h6 {
  font-size: 14px;
}

.rooms-bg {
    margin: 2em 0;
    background-color: white;
    text-align: right;
}

.form-bg {
    background-color: white;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 2px solid #d3d3d3;
    text-align: right;
  }
  .first_div{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .hotelIcon{
    
    margin-left: 15px;
    position: relative;
    bottom: 9px;

  }
  .Carousel-root-3 {
      height: 85% !important;
  }

  .carrousalBody {
    max-width: 600px;
  }

  .border_all{
    border: 1px solid #aaa;
  }
  
  .leftSmall_div{
      line-height: 3;
      padding: 1em;
      min-height: 8rem;
  }

  .icon_size{
      /* font-size: 35px; */
  }
  /*.line{
    width: 100%;
    height: 2px;
    background-color: #666;
  }*/
  .description{
    text-align: justify;
    padding: 17px;
  }

  .border_bottom{
    border-bottom: 2px solid lightgrey;
    border-top: 2px solid lightgrey;
  }
  .border_right{
    border-right: 2px solid lightgrey;
  }
  .composition-btn {
    /* width: 100%; */
    float: left;
    background-color: #1e5881 !important;
    font-size: 20px !important;
    color: white !important;
    margin-top: 0.8rem;
  }
 .accordonBorder{
  border: 2px solid lightgrey;
  /* margin-top: 0; */
  border-top: none;
 }
 /* .MuiPaper-root .MuiAccordion-root .Mui-expanded .MuiAccordion-rounded .MuiPaper-elevation1 .MuiPaper-rounded{
   margin-top: 0 !important;
   margin-bottom: 0 !important;
 } */
 .MuiAccordion-root.Mui-expanded{
   margin: 0 !important;
   font-size: 1.2em;
 }

 .UserNotificationModal img {
    width: 100%;
 }

 .UserNotificationModal .heading h3 {
  color: #cf576f;
  font-weight: bold;
 }

 .UserNotificationModal .content h3 {
   color: #000;
   font-weight: normal;
 }

  .imgCounter{
    background-color: #666666bf;
    color: white;
    padding: 7px;
    border-radius: 4px;
    position: absolute;
    margin: -60px 25px;
  }
  
  .dual-div {
    padding: 0 16px;
  }
  
  hr {
    margin: 0;
    height: 2px;
  }
  
  .borderDiv {
    border-bottom: 2px solid;
    margin-bottom: 13px;
   
  }
  .iconsDiv{
    padding:18px
  }
  .Carousel-indicators-4 {
    display:none;
  }

  .carousalImage {
    height: 100%;
    /* width: 100%; */
  }
  .carrousalDiv {
    height: 400px;
  }
  .rightSmall {
    padding: 15%;
  }
  .leftSmall {
    padding: 16.2%;
  }
  .lineRight {
    width: 85%;
    height: 2px;
    background-color: beige;
    margin-right: auto;
  }
  .lineLeft {
    width: 85%;
    height: 2px;
    background-color: beige;
    margin-left: auto;
  }
  
  .blank-div {
    /*height: 100px;
    width: 100px;
    background-color: #666;*/
    display:none;
  }
  
  .cheapButton {
    background-color: #01bddddb;
    float: left;
    height: 70%;
    color: white;
    border: none;
    border-radius: 7px;
    padding: 10px;
    text-align: center;
  }
  .reserveButton , .cancelButton{
    background-color: #1e5881;
    border-radius: 10px;
    width: 100%;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 15px;
  }
  .cheapButton2 {
    background-color: #01bddddb;
    float: left;
    height: 70%;
    color: white;
    border: none;
    /* border-radius: 7px; */
    padding: 10px;
    text-align: center;
  }

  .cheapButton {
    background-color: #1e5881;
    float: left;
    height: 70%;
    color: white;
    border: none;
    /* border-radius: 7px; */
    padding: 10px;
    text-align: center;
  }
  .cheapDiv {
    margin-right: 2%;
    margin-bottom: 2%;
  }
  #ratingDiv {
    border: 2px solid lightgrey;
    border-top: 0;
    border-right: 0;
  }
  #gradeDiv {
    border-bottom: 2px solid lightgrey;
  }
  #wifiDiv {
    border-right: 2px solid lightgrey;
    margin-right: -1%;
    height: 95%;
    border-bottom: 2px solid lightgrey;
  }
  #poolDiv {
    border-right: 2px solid lightgrey;
    margin-right: -1%;
  }
  
  .multipleOptionsGridConatiner {
    text-align: center;
    padding: 5%;
  }
  #boxShadow {
    box-shadow: 4px 6px 9px 4px #888888 !important;
  }
  .mobile-text-center{
    margin: 5px 0px 16px 0px;
  }
  .iconSize{
    height:33px;
    width:33px;
    opacity: 0.65 !important;
  }
  .bb{
    border-bottom: 1px solid lightgrey;
  }
  .br{
    border-right: 1px solid lightgrey;
  }
  .bl{
    border-left: 1px solid lightgrey;
  }
  .bt{
    border-top: 1px solid lightgrey;
  }

  .flightBorderDiv {
    font-size: 1.1em;
    justify-content: center;
    margin: 0 auto;
  }

  .flightBorderDiv div {
    align-self: center;
    min-width: 3rem;
  }

  .flightDetailsBox {
    text-align:center;
  }

  .floatingSummaryBox {
    font-size: 1rem;
  }

  .floatingSummaryBox span.amount {
    font-size: 1.7rem;
    margin: 0 0 0 0.2rem;
    font-weight:bold;
  }

  #packageOptions .content {
    padding: 0 1em 0 0;
    font-size: 1.1em;
    font-weight: normal;
  }

  #packageOptions .label {
    background-color: #01bddddb;
    float:left;
    color:#fff;
    padding:0.5em 1em;
  }


  #packageOptions h3 {
    font-size: 1.6em;
    font-weight: bold;
    padding: 0;
    padding-top: 0.8em;
  }

  #packageOptions .basicOption {
    border:1px solid #bbb;
    font-weight:bold;
  }

  #packageOptions .basicOption button {
    background: #eee;
    color: #777;
    border:1px solid #999;
  }
  
  #packageOptions .basicOption h4 {
    font-weight: normal;
  }

  #packageOptions .basicOption h5 {
    font-weight: normal;
  }

  #packageOptions h4 {
    font-size: 1.4em;
    
    color: #000;
  }

  #packageOptions h5 {
    padding-bottom: 0.5em;
    color: #666;
    font-weight: bold;
  }

  #packageOptions ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-weight: normal;
  }

  #packageOptions ul li:before {
    content: '✓';
    padding-left: 0.5em;
    font-weight: bold;
    color: #cf5770;
  }

  #packageOptions ul li.hideBullet:before {
    content: '';
    padding-left: 1.3em;
  }

  #packageOptions li {
    padding: 0.5em 0 0; 
    font-weight: 600;
  }

  li.hideBullet {
    padding: 0 !important;
  }

  #packageOptions ul li span {
    color: #1e5881; /*#cf5770;*/
    text-decoration: none;;
    font-weight:bold;
  }

  
  #packageOptions button {
    margin: 0;
    padding: 0;
    height: 35px;
    display: block;
    width: 85%;
    background-color: #1e5881;
  }

  .MuiAlert-action {
    padding-right: 16px;
    padding-left: 0 !important;
  }

  .MuiAlert-icon {
    margin-right: 0 !important;
    margin-left: 12px;
  }

  @media only screen and (max-width: 420px) {
    .carrousalBody{
      width: 110%;
      position: relative;
      right: -5%;
      /*width: 104%;
      position:relative;
      right: -2%;*/
      margin-bottom: -2em;
    }

    .flightHeaderFirstCol {
      border-bottom:2px solid lightgrey;
      border-left:2px solid lightgrey ;
    }
  
    .flightHeaderSecondCol {
      border-bottom:2px solid lightgrey;
    }

    
    .flightDetailsBox {
      padding:0 10px;
    }
  }

  .modalPassengersUpdate {
    font-size: .9rem;
  }

  .modalPassengersUpdate .plus,
  .modalPassengersUpdate .minus,
  .modalPassengersUpdate .caption {
    font-size: 1rem;
    padding:0;
    font-weight:bold;
    cursor:pointer;

  }


  @media only screen and (min-width: 400px) and (max-width: 420px) {
    .carrousalBody{
      width: 108%;
      position: relative;
      right: -4%;
    }
  }
  .floatButton{
    position: sticky;
  bottom: 0
  }
  .mob_view{
    display: none;
  }
  @media only screen and (max-width: 500px) {
   
    .rooms-bg {
        margin: 2em 15px;
    }

    /*.form-bg {
        margin-bottom: 0;
    }*/

    .floatButton{
      position: sticky;
    bottom: 0;
        margin-right: -4%;
    width: 109%;
    }
    .mob_view{
      display: block;
    }
    .web_view{
      display: none;
    }
    .pl_pr_none{
      padding-left: 0;
      padding-right: 0;
    }
    .hr_seperator{
      visibility: hidden;
    }
    .icon_mob_ver{
      width: 110% !important;
      margin-right: -5%;
      font-size: 12px;
      border-top: 1px solid rgb(204,204,204);
    }
    .icon_mob_ver1{
      width: 110% !important;
      margin-right: -5%;
    }
    .desktopIcons {
      display:none;
    }
    .mob_date{
      margin-right: 3%;
    }
    .carrousalDiv {
      /*height: 315px;*/
      height: 275px;
    }

    .carousalImage {
      width: 100%;
      height: 275px;
      background-size: cover !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
    }

    .carrousalBody .jss4 {
      margin-top: 0;
    }

    .imgCounter{
      margin: -30px 0;
    }

    .leftSmall_div{
      height: 90px;
      line-height: 2;
      padding: 2em;
    }
    .iconsDiv{
      padding: 0;
    }
    .flightBorderDiv {
      border-top: 2px solid lightgrey;
      font-size: 1em;
    }  
    #divToshift {
      display: none;
      width: fit-content;
      /* position: absolute; */
      margin-top: -230px;
      margin-right: auto;
      border-right: 2px solid lightgrey;
      padding-right: 13%;
    }
    .heading_centered{
      margin-right: 14%;
    }

    

    #borderDiv {
      border-top: 2px solid lightgrey;
      justify-content: center;
      margin: 0 auto;
    }

    #borderDiv div {
      align-self: center;
      min-width: 3rem;
    }

    .media-flex-div {
      font-size:13px;
      font-weight: 300;

    }

    .dflex {
      font-size: 13px;
    }

    .mobile-text-center{
        text-align: center;

    }
  
    .blank-div {
      display: none;
    }
    
    .headingBorder{
        padding-top: 13px;
        border-top: 2px solid lightgrey;
    }
    .justify_mob{
        justify-content: center;
    }
    .mob_display_none{
        display: none;
    }
    .carrousal_title{
        text-align: center;
        margin-bottom: 10px;
    }
    .composition-btn{
        font-size: 15px !important;
    }
    .leftSmall_div{
        /*padding: 20px !important;*/
    }
    
    .mapIcon {
      padding-top: 1em !important;
    }

  }
  @media only screen and (min-width: 400px) and (max-width: 500px) {
    #divToshift {
      text-align: center;
      width: fit-content;
      margin-top: -198px !important;
      margin-right: auto;
    }
  }
  @media only screen and (min-width: 300px) {
    #divToshift {
      text-align: center !important;
      width: fit-content;
      /* margin-right: auto; */
    }
 
  }
  @media only screen and (min-width: 400px) and (max-width: 450px) {
    #divToshift {
      padding-right: 18% !important;
    }
  }
  
  @media only screen and (min-width: 330px) and (max-width: 483px) {
    #divToshift {
      width: fit-content;
      /* position: absolute; */
      margin-top: -193px;
      /* margin-right: auto; */
      padding-right: 14%;
    }
    #wifiDiv {
      margin-right: -1.5%;
    }
    .rightSmall {
      padding: 6%;
    }
    .leftSmall {
      padding: 8%;
    }
    .multipleOptionsGridConatiner {
      padding-top: 6% !important;
      padding: 0%;
      /* border:1px solid red */
    }
    .leftSmall_div{
        /*padding: 9px !important;*/
    }
  }
  
  @media only screen and (max-width: 320px) {

    .leftSmall_div{
        padding: 0px !important;
    }


  }
  @media only screen and (min-width: 500px) {

    .cheapButton2{
        width: 15% !important;
    }


  }