/* General Reset */
.booking_form .ant-picker {
    direction: rtl;
    text-align: right;
  }

  

.booking_form .row {
    justify-content: space-around !important;
    align-items: center !important;
}

.booking_form .col-md-6 {width: 45% !important}
.booking_form .col-md-4 {width: 30% !important;}
.booking_form .col-md-12 {width: 100% !important;}
.booking_form .col-md-3 {width: 20% !important;}
.booking_form .col-md-1 {width: 7% !important;}


.booking_form .row>* {
    
    width: inherit !important;
}

/* Container */
.booking_form .container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

/* Headers */
.booking_form h1, 
.booking_form h3 {
    text-align: right; /* Ensure headers align to the right */
    color: #333;
    margin-bottom: 20px;
}

.booking_form h1 {
    font-size: 28px;
    font-weight: bold;
}

.booking_form h3 {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}

/* Form Styling */
.booking_form .ant-form-item-label > label {
    font-weight: 500;
    font-size: 16px;
    color: #333;
    text-align: right; /* Label alignment */
}

.booking_form .ant-input,
.booking_form .ant-picker,
.booking_form .ant-select-selector {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 8px;
    font-size: 14px;
    text-align: right; /* Input text alignment */
    transition: border-color 0.3s ease;
}

.booking_form .ant-input:focus,
.booking_form .ant-picker-focused,
.booking_form .ant-select-focused .ant-select-selector {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
}

/* Buttons */
.booking_form button.ant-btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.booking_form button.ant-btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.booking_form button.ant-btn-dashed {
    color: #007bff;
    border-color: #007bff;
    background: #f9f9f9;
    transition: all 0.3s ease;
}

.booking_form button.ant-btn-dashed:hover {
    background: #e7f3ff;
    color: #0056b3;
    border-color: #0056b3;
}

/* Row and Column Layout */
.booking_form .row {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    margin-bottom: 20px;
    justify-content: flex-end; /* Ensure items align to the right */
}

.booking_form .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: right; /* Align column content to the right */
}

.booking_form .col-md-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    text-align: right; /* Align column content to the right */
}

.booking_form .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: right; /* Align column content to the right */
}

/* Textarea */
.booking_form textarea.ant-input {
    resize: none;
    text-align: right; /* Align textarea text */
}

/* Misc */
.booking_form .mb-3 {
    margin-bottom: 15px !important;
}

.booking_form .mb-4 {
    margin-bottom: 20px !important;
}

.booking_form .text-center {
    text-align: center; /* Override for specific center-aligned content */
}

.booking_form .my-5 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}


.booking_form .ant-form-item-control-input {
    text-align: right; /* Ensure form controls are aligned */
}

.booking_form .ant-btn {
    text-align: center; /* Buttons stay centered */
}

.booking_form .passengers_form.remarks  .ant-form-item-label {text-align: right; }
.booking_form .contact_form  .ant-form-item-label {text-align: right; }
.booking_form {direction: ltr; max-width: 584px !important; }
.booking_form .passengers_form {direction:rtl;}
.booking_form button {height: 3em;}
.booking_form .details {font-size: 1.2em; margin-bottom: 2.5em; gap: 0;}

@media (min-width: 768px) {
    .booking_form .col-md-6 {width: 45% !important}
    .booking_form .col-md-4 {width: 30% !important;}
    .booking_form .col-md-12 {width: 100% !important;}
    .booking_form .col-md-3 {width: 20% !important;}
    .booking_form .col-md-1 {width: 7% !important;}

}

@media (max-width: 500px) {
    .booking_form h1 {font-weight: 800 !important; text-align: center; margin: 0 auto 1em !important;}
    .booking_form .contact_form.row {display: block; padding: 0 1em 1em;}
    .booking_form .contact_form .col-md-6 {display: initial; width: 100% !important; padding: 0;}
    .booking_form .contact_form .col-md-4 {width: 30% !important;padding: 0}
    .booking_form .contact_form .col-md-12 {width: 100% !important;}
    .booking_form .contact_form .col-md-3 {width: 20% !important;}
    .booking_form .contact_form .col-md-1 {width: 7% !important;}

    .booking_form .passengers_form.row { flex-wrap: nowrap !important; gap: 0 !important;}
    .booking_form .passengers_form .col-md-6 {width: 45% !important;}
    .booking_form .passengers_form .col-md-4 {width: 30% !important; padding:0;}
    .booking_form .passengers_form .col-md-12 {width: 100% !important; }
    .booking_form .passengers_form .col-md-3 {width: 25% !important; padding:0;}
    .booking_form .passengers_form .col-md-1 {width: 7% !important;}

    .booking_form .remarks {margin-top: 1em;}
}