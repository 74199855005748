#vacations .vacation {background: #fff; padding:0; filter: drop-shadow(3px 3px 5px #aaa);background: #fff;}
#vacations h1 {margin: 1em;}
#vacations .overlay {width: 325px;}
#vacations .vacation img {width: 100%; height: 215px;}
#vacations .vacations_wrapper {display: flex; flex-wrap: wrap; justify-content: center;}
#vacations .vacation h1 {line-height: 1.2; font-size: 1.3em;}

@media (max-width: 500px) {
    #vacations .vacation {max-width: inherit;}
    #vacations h1 {margin: .5em;}
    #vacations .overlay {width: 100%;}
    #vacations .vacations_wrapper { display: block;}

}