#SuperSpinner {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  position:relative;
  padding: 0;
}

#SuperSpinner .spinnerWrapper {
    height: 100%;
}

#SuperSpinner .spinnerLine {
  animation: superSpin 5s linear infinite;
  -webkit-animation: superSpin 5s linear infinite;
  background: linear-gradient(to top, rgba(255, 255, 255, 0) 50%, rgb(18 75 113)  100%);  /*rgb(14 173 154)*/ /* rgb(207 87 111) */
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
}

#SuperSpinner .spinnerLoader {
  border-radius: 50%;
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  position: absolute;
}  

#SuperSpinner .image {
  background: #fff  center no-repeat;
  border-radius: 50%;
  height: 100%;
}

#SuperSpinner .content {
    margin: 1em auto;
    text-align: center;
    color: rgb(18 75 113); /*rgb(41, 163, 131);*/
    font-weight: bold;
    font-size: 1.5em;
}

@-webkit-keyframes superSpin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes superSpin {  
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 420px) {

  #SuperSpinner {
    width: 250px;
    height: 250px;
    margin: 0 auto;
    position:relative;
    padding: 0;
  }

  #SuperSpinner .spinnerLoader {
    border-radius: 50%;
    margin: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    position: absolute;
  } 
 
}