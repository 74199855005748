.feedback img {
    width:100%;
    height: 100%;
    margin:0 auto;
}

div#vacation > h1 {
    color: #1f5981;
    font-weight: 800 !important;
    font-size: 2em !important;
    margin: .5em .5em 1em !important;
}

@media (max-width: 500px) {
    div#vacation > h1 {
        color: #1f5981;
        font-weight: 800 !important;
        font-size:1.5em !important;
    }
}