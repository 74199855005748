a.searchBoxButton {
    text-decoration: none;   
}

.homepage-intro-content .searchBox {
    
    width: 100%;
    display: flex;
    border: 1px solid #a1a1a1;
    background: #fff;
    border-radius: 40px;
    flex-direction: row;
    justify-content: space-between;
    font-family: Assistant;
    font-size: 1.3rem;
    font-weight: 600;
    color: #555;
    width: 95%;
    margin: 3.5em auto 1em;
    -webkit-filter: drop-shadow(3px 3px 4px #444);
    filter: drop-shadow(1px 1px 4px #444);
    max-width: 800px;
    margin: 0 auto;
    min-height: 58px;
    margin-top: 3em;
  }

  .homepage-intro-content .searchBox .left{ 
    display: flex;
    padding: 0 .25em 0 .5em;
    align-items:center;
    padding: .5em .5em .5em 1em;
    border-right: 1px solid #999;
    color: #333;
  }

  .homepage-intro-content .searchBox .right{ 
    display: flex;
    padding: .5em .75em .5em .5em;
    align-items:center;
  }

  @media only screen and (max-width:500px) {
    .homepage-intro-content .searchBox {
      width: 100%;
      display: flex;
      border: 1px solid #a1a1a1;
      background: #fff;
      border-radius: 40px;
      flex-direction: row;
      justify-content: space-between;
      font-family: 'Assistant';
      font-size: 1rem;
      font-weight: 600;
      color: #555;
      width: 95%;
      margin: 3.5em 0  1em;
      -webkit-filter: drop-shadow(3px 3px 4px #444);
      filter: drop-shadow(1px 1px 4px #444);
      min-height: inherit;
    }
  }